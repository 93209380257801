<template>
  <div class="detail-prop-book">
    <div class="wrapper">
      <a href="#" @click="$router.go(-1)" class="bck cvr-bg-bf"></a>
      <a href="#" class="bck">Booking Page</a>
      <div class="rowflex">
        <div class="info">
          <div class="prop">
            <template v-if="property.mediaDescription">
              <template v-if="checkType(property.mediaDescription[0]) == 'img'">
                <figure>
                  <router-link :to="`/property/${this.$route.params.id}`">
                    <img :src="property.mediaDescription[0]" width="160" />
                  </router-link>
                </figure>
              </template>
              <template v-else>
                <video style="width: 100%">
                  <source
                    :src="property.mediaDescription[0]"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </template>
            </template>
            <template v-else>
              <router-link :to="`/property/${this.$route.params.id}`">
                <img src="/img/dummy/home/11.jpg" width="160" />
              </router-link>
            </template>
            <div class="caption">
              <h3>
                <router-link :to="`/property/${this.$route.params.id}`">
                  {{ property.name }}
                </router-link>
              </h3>
              <div class="location cvr-bg-bf">
                <span>{{ property.region }}, {{ property.city }}</span>
                <span v-if="property.furnishedType">
                  {{ property.furnishedType.name }}
                </span>
              </div>
            </div>
            <!-- end of caption -->
          </div>
          <!-- end of prop -->
          <div class="modif">
            <table>
              <tr>
                <td colspan="3">
                  <label>Transaction Type</label>
                  <p>{{ transactionType }}</p>
                </td>
              </tr>
              <template v-if="$route.params.transactionType === 'buy'">
                <tr>
                  <td colspan="3">
                    <label>Planned Purchase & Settlement Date</label>
                    <p>{{ $store.state.booking.moveDate | date_only }}</p>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>
                    <label>Planned Move In Date</label>
                    <p class="clearfix">
                      <span>
                        {{
                          $store.state.booking.transaction.moveDate | date_only
                        }}
                      </span>
                    </p>
                  </td>
                  <td colspan="2">
                    <label>
                      End of Rental Period
                      <template v-if="transactionType === 'Rent-to-Own'">
                        & Purchase Date
                      </template>
                    </label>
                    <p class="clearfix">
                      <span>
                        {{ $store.state.priceDetail.endDateRental | date_only }}
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Payment Option</label>
                    <p class="clearfix">
                      <span>
                        {{ $store.state.priceDetail.paymentOption }}
                      </span>
                    </p>
                  </td>
                  <td colspan="2">
                    <label>Rent Period</label>
                    <p class="clearfix">
                      <span>
                        {{ $store.state.priceDetail.rentPeriod }}
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Effective Rent Per Month </label>
                    <b>
                      Rp
                      {{
                        $store.state.priceDetail.rateEffectivePerMonth
                          | convertToRupiah
                      }}
                    </b>
                  </td>
                  <td>
                    <label>Total Rent</label>
                    <b>
                      Rp
                      {{
                        $store.state.priceDetail.rentAmount | convertToRupiah
                      }}
                    </b>
                  </td>
                  <td v-if="transactionType === 'Rent-to-Own'">
                    <label>Price At End Of Rental Period</label>
                    <b>
                      Rp
                      {{
                        $store.state.priceDetail.propertyPriceEndOfRentalPeriod
                          | convertToRupiah
                      }}
                    </b>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>
        <!-- end of info -->
        <div class="action">
          <div
            class="sum clearfix"
            v-if="$route.params.transactionType === 'buy'"
          >
            <span>Price</span>
            <b>
              Rp. {{ $store.state.booking.transaction.rates | convertToRupiah }}
            </b>
          </div>
          <div
            class="sum clearfix"
            v-if="$route.params.transactionType !== 'buy'"
          >
            <span>Total Rent</span>
            <b>
              Rp. {{ $store.state.priceDetail.rentAmount | convertToRupiah }}
            </b>
          </div>
          <!-- end of sum -->
          <template v-if="$route.params.transactionType !== 'buy'">
            <a
              href="#"
              class="cvr-bg-af trigger-detail"
              :class="{ showing: showDetailPayment === true }"
              @click.prevent="showDetailPayment != showDetailPayment"
            >
              See more for payment details
            </a>
            <div
              class="detail"
              v-if="showDetailPayment"
              style="display: block;"
            >
              <table>
                <tbody>
                  <tr
                    v-for="(payment, index) in $store.state.booking
                      .paymentSchedules"
                    :key="index"
                  >
                    <td>{{ payment.name }}</td>
                    <td>{{ payment.dueAt | date_only }}</td>
                    <td>Rp. {{ payment.amount | convertToRupiah }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <!-- end of detail -->
          <div class="book clearfix">
            <span>Booking Fee</span>
            <template v-if="$route.params.transactionType === 'buy'">
              <strong>
                Rp.
                {{
                  $store.state.booking.paymentSchedules[0].amount
                    | convertToRupiah
                }}
              </strong>
            </template>
            <template v-else>
              <strong>
                Rp.
                {{
                  $store.state.booking.paymentSchedules.find(
                    item => item.name === "Booking Fee"
                  ).amount | convertToRupiah
                }}
              </strong>
            </template>
          </div>
          <div
            class="book pad clearfix"
            v-if="$route.params.transactionType === 'buy'"
          >
            <span>Purchase Settlement</span>
            <strong>
              Rp.
              {{
                $store.state.booking.paymentSchedules[1].amount
                  | convertToRupiah
              }}
            </strong>
          </div>
          <!-- end of sum -->
          <form @submit.prevent="proccessPayment">
            <b>Select Payment Method:</b>
            <label>
              <input
                type="radio"
                name="payment_method"
                value="cash"
                checked="checked"
                v-model="paymentMethod"
              />
              <span>Cash (In Office)</span>
            </label>
            <label>
              <input
                type="radio"
                name="payment_method"
                value="credit_card"
                v-model="paymentMethod" />
              <span>Debit/Credit Card</span>
            </label>
            <label>
              <input
                type="radio"
                name="payment_method"
                value="bank_transfer"
                v-model="paymentMethod"
              />
              <span>Bank Account/Virtual Account</span>
            </label>
            <button type="submit" class="button">Proceed to Payment</button>
          </form>
        </div>
        <!-- end of action -->
      </div>
      <!-- end of rowflex -->
      <!-- popup pilihan bank -->
      <div
        id="popup-bank"
        class="popup-addprop"
        style="width: 35%; display: none;"
      >
        <h3>Select Bank Account</h3>
        <form @submit.prevent="bankTransfer">
          <div class="fgroup">
            <fieldset class="cvr-bg-af">
              <select v-model="selectedBank">
                <option :value="null">
                  Pilih Bank
                </option>
                <option
                  v-for="(bank, index) in bankAccounts"
                  :key="index"
                  :value="bank"
                >
                  {{ bank.toUpperCase() }}
                </option>
              </select>
            </fieldset>
          </div>
          <div class="fgroup">
            <button
              type="submit"
              class="button"
              :disabled="selectedBank === null"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <a ref="ShowBank" class="vhidden" data-src="#popup-bank" data-fancybox>
        PopUp Pilih Bank
      </a>
      <!-- popup form CC -->
      <div
        id="popup-cc"
        class="popup-addprop"
        style="width: 35%; display: none;"
      >
        <h3>Input Card Information</h3>
        <form @submit.prevent="submitCC">
          <div class="fgroup">
            <label>Card Number</label>
            <div class="gap">
              <input
                type="number"
                min="0"
                step="1"
                v-model="cc.cardNumber"
                value=""
                placeholder="Card Number"
                required
              />
            </div>
            <!-- end of gap -->
          </div>
          <div class="fgroup">
            <label>CVV</label>
            <div class="gap">
              <input
                type="number"
                min="0"
                step="1"
                v-model="cc.cvv"
                value=""
                placeholder="CVV"
                required
              />
            </div>
            <!-- end of gap -->
          </div>
          <div class="fgroup">
            <label>Expired Month</label>
            <div class="gap">
              <input
                type="number"
                min="0"
                step="1"
                v-model="cc.expMonth"
                value=""
                placeholder="Expired Month"
                required
              />
            </div>
            <!-- end of gap -->
          </div>
          <div class="fgroup">
            <label>Expired Year</label>
            <div class="gap">
              <input
                type="number"
                min="0"
                step="1"
                v-model="cc.expYear"
                value=""
                placeholder="Expired Year"
                required
              />
            </div>
            <!-- end of gap -->
          </div>
          <div class="fgroup">
            <button type="submit" class="button">
              Submit
            </button>
          </div>
        </form>
      </div>
      <a ref="ShowCC" class="vhidden" data-src="#popup-cc" data-fancybox>
        PopUp Input CC
      </a>
    </div>
    <!-- end of wrapper -->
  </div>
  <!-- end of detail prop book-->
</template>

<script>
export default {
  name: "BookingProperty",
  data() {
    return {
      property: {},
      paymentMethod: "cash",
      bankAccounts: ["bca"],
      selectedBank: null,
      cc: {
        cardNumber: null,
        cvv: null,
        expMonth: null,
        expYear: null
      },
      showDetailPayment: false
    };
  },
  computed: {
    transactionType() {
      let type = null;
      if (this.$route.params.transactionType === "buy") {
        type = "Buy";
      } else if (this.$route.params.transactionType === "rent") {
        type = "Rent";
      } else {
        type = "Rent-to-Own";
      }
      return type;
    }
  },
  async mounted() {
    await this.getDetailProperty();
    if (this.$store.state.booking === null) {
      this.$router.push(`/property/${this.$route.params.id}`);
    }
  },
  methods: {
    async getDetailProperty() {
      await this.$axios
        .get(`/properties/${this.$route.params.id}`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          console.log(response.data);
          this.property = response.data.data;
        });
    },
    proccessPayment() {
      if (this.paymentMethod === "cash") {
        this.$axios
          .post("/payments/checkout/first-payment", {
            transactionId: this.$store.state.booking.transaction.id,
            paymentType: this.paymentMethod,
            paymentInfo: {}
          })
          .then(response => {
            this.$swal({
              icon: "success",
              text: response.data.message
            });
            this.$router.push(
              `/account/transaction/${this.$store.state.booking.transaction.id}`
            );
          });
      } else if (this.paymentMethod === "bank_transfer") {
        this.$refs.ShowBank.click();
      } else {
        this.$refs.ShowCC.click();
      }
    },
    bankTransfer() {
      this.$axios
        .post("/payments/checkout/first-payment", {
          transactionId: this.$store.state.booking.transaction.id,
          paymentType: this.paymentMethod,
          paymentInfo: {
            bank: "bca"
          }
        })
        .then(response => {
          const close = document.getElementsByClassName("fancybox-close-small");
          close[0].click();
          this.$store.commit("SET_CHECKOUT", response.data.data);
          this.$router.push(
            `/property/${this.$route.params.id}/booking/buy/complete`
          );
        });
    },
    submitCC() {
      this.$axios
        .post("/payments/checkout/first-payment", {
          transactionId: this.$store.state.booking.transaction.id,
          paymentType: this.paymentMethod,
          paymentInfo: {
            card_number: this.cc.cardNumber,
            card_cvv: this.cc.cvv,
            card_exp_month: this.cc.expMonth,
            card_exp_year: this.cc.expYear
          }
        })
        .then(response => {
          const close = document.getElementsByClassName("fancybox-close-small");
          close[0].click();
          this.$swal({
            icon: "success",
            text: response.data.message
          });
          this.$router.push(
            `/account/transaction/${this.$store.state.booking.transaction.id}`
          );
        });
    }
  }
};
</script>

<style scoped>
.pad {
  border-top: 0px !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>
